<template>
  <b-card
    v-if="that.storeList"
    class="ecommerce-card pt-pb"
    no-body
  >
    <div class="item-img text-center">
      <div class="w-100">
        <figure
          @mouseenter="$superApp.helper.zoomMagic"
          @mouseout="$superApp.helper.zoomOut"
        >
          <v-zoomer
            :zoomed.sync="$superApp.zoomed"
            :mouse-wheel-to-zoom.sync="$superApp.mouseWheelToZoom"
          >
            <b-img
              :alt="`${product.main_service.service_name}`"
              fluid
              class="card-img-top"
              :src="$helpers.imageHelper(product.main_service.picture)"
              @error="$helpers.imageError"
            />
          </v-zoomer>
        </figure>
      </div>
    </div>
    <b-card-body :class="{ 'p-1' : isMobile }">
      <div
        v-if="!isMobile"
        class="item-wrapper"
      >
        <div>
          <h6 class="item-price">
            {{ that.storeList.currency_symbol }} {{ product.base_fare }}
          </h6>
        </div>
      </div>
      <h6 class="item-name">
        <b-link class="text-body">
          {{ product.main_service.service_name }}
        </b-link>
      </h6>
      <b-card-text
        v-if="that.getServiceDescription(product)"
        class="item-description d-block auto-text"
        :class="{ 'mb-0': that.itemView === 'list-view' }"
      >
        {{ product.showFull ? that.getServiceDescription(product) : that.getText(that.getServiceDescription(product)) }}
        <span
          v-if="that.canShowLabel(that.getServiceDescription(product))"
          class="small font-weight-bold text-right"
          style="word-break: keep-all !important;"
          @click="that.toggleViewMore(product)"
        >
          <feather-icon
            :icon="product.showFull ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            class="cursor-pointer"
            size="15"
          />
        </span>
      </b-card-text>
      <div
        v-if="isMobile"
        class="d-flex align-items-center justify-content-between"
      >
        <div class="item-wrapper">
          <h6 class="item-price">
            {{ that.storeList.currency_symbol }}{{ product.base_fare }}
          </h6>
        </div>
        <div
          class="item-options text-right mt-0 cart-item-qty mx-0"
        >
          <b-form-spinbutton
            v-if="that.isAddedToCart(product)"
            v-model="that.addedProduct(product).quantity"
            min="0"
            size="sm"
            @change="that.addItemToCart(product)"
          />
          <b-button
            v-if="!that.isAddedToCart(product)"
            :variant="that.isAddedToCart(product) ? 'danger' : 'primary'"
            tag="a"
            class="btn-cart px-1"
            @click="that.addItemToCart(product)"
          >
            <span>{{ that.isAddedToCart(product) ? 'Remove' : 'Add' }}</span>
          </b-button>
        </div>
      </div>
    </b-card-body>
    <div
      v-if="!isMobile"
      class="item-options text-center"
    >
      <b-button
        v-if="that.isAddedToCart(product)"
        :variant="'primary'"
        class="cart-item-qty btn-cart"
      >
        <b-form-spinbutton
          v-model="that.addedProduct(product).quantity"
          min="0"
          size="sm"
          @change="that.addItemToCart(product)"
        />
      </b-button>
      <b-button
        v-if="!that.isAddedToCart(product)"
        :variant="that.isAddedToCart(product) ? 'danger' : 'primary'"
        tag="a"
        class="btn-cart"
        @click="that.addItemToCart(product)"
      >
        <span>{{ that.isAddedToCart(product) ? 'Remove' : 'Add' }}</span>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BImg, BCardBody, BLink, BButton, BCardText, BFormSpinbutton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BCardText,
    BCardBody,
    BFormSpinbutton,
    BLink,
    BButton,
  },
  props: {
    that: {
      type: Object,
      default: () => {},
    },
    filteredStore: {
      type: Object,
      default: () => {},
    },
    product: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters['app/windowSize'] <= 767
    },
  },
}
</script>
