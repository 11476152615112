<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-1 relative"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <span class="badge badge-up badge-pill badge-primary cart-items-counter">{{ cartItemsCount }}</span>
      <b-img
        id="cart-dropdown-menu"
        style="height: 25px"
        class="mr-0 ml-0 ml-mobile-0"
        :src="require('@/assets/images/app-icons/cart.png')"
        :badge="cartItemsCount"
      />
    </template>

    <!-- Header -->
    <li
      v-if="!isShopOrProvider"
      class="dropdown-menu-header"
    >
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('My Cart') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ cartItemsCount }} {{ $t('Items') }}
        </b-badge>
      </div>
    </li>
    <b-modal
      id="product-add-ons"
      v-model="showAddOns"
      :title="$t('Would you like to add any of this?')"
      :no-close-on-backdrop="true"
      @hidden="showAddOns = false"
      @ok="addItemsOns"
    >
      <div
        v-if="addOnsPayload.itemsaddon && addOnsPayload.itemsaddon.length > 0"
        class="col-md-12"
      >
        <b-form-checkbox-group
          v-model="selectedAddOns"
          name="event-filter"
          stacked
        >
          <b-form-checkbox
            v-for="item in addOnsPayload.itemsaddon"
            :key="item.id"
            name="event-filter"
            :value="item.id"
            class="mb-1"
            :class="`custom-control-primary`"
          >
            {{ item.addon_name }} {{ canShowPrice(item.price) ? ($t('at') + ' ' + item.price) : '' }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </b-modal>
    <!-- Cart Items Store -->
    <li
      v-if="cartItems && cartItems.length > 0 && !isServicePage"
      class="ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
    >
      <b-media
        v-for="item in cartItems"
        :key="item.id"
        class="flex-wrapper-cart"
      >
        <template
          v-if="item.product"
          #aside
        >
          <b-img
            :src="$helpers.imageHelper(item.product.picture)"
            :alt="item.product.item_name"
            rounded
            style="height: 50px; width: 50px;"
            @error="$helpers.imageError"
          />
        </template>
        <div
          v-if="item.product"
          class="media-heading w-100"
        >
          <h6 class="cart-item-title mb-0">
            <b-link class="text-body">
              {{ item.product.item_name }}
            </b-link>
          </h6>
          <b-link
            v-if="item.cartaddon && item.cartaddon.length > 0 && !isShopOrProvider"
            class="text-body mt-1"
            @click="editAddOns(item)"
          >
            {{ $t('Customize') }}
          </b-link>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <h5
            v-if="item.store"
            class="cart-item-price text-left"
          >
            {{ item.store.currency_symbol }} {{ item.total_item_price }}
          </h5>
          <div class="cart-item-qty">
            <b-form-spinbutton
              v-if="!isShopOrProvider"
              v-model="item.quantity"
              min="0"
              size="sm"
              @change="updateQuantity(item)"
            />
            <form-spin-button
              v-else
              :product="item.product"
              class="spin-button-menu-creation"
              :cart-product="item"
              @value-changed="addItemToCart"
            />
          </div>
        </div>
      </b-media>
    </li>

    <!-- Cart Items Service -->
    <li
      v-if="cartItems && cartItems.length > 0 && isServicePage"
      class="ps-container scrollable-container media-list scroll-area ps ps--active-y auto-flow-scroll"
      tagname="li"
    >
      <b-media
        v-for="item in cartItems"
        :key="item.id"
        class="flex-wrapper-cart"
      >
        <template #aside>
          <b-img
            v-if="item.provider_service"
            :src="$helpers.imageHelper(item.provider_service.main_service ? item.provider_service.main_service.picture : null)"
            :alt="item.provider_service.main_service ? item.provider_service.main_service.service_name : ''"
            rounded
            style="height: 50px; width: 50px;"
            @error="$helpers.imageError"
          />
        </template>
        <div
          v-if="item.provider_service"
          class="media-heading"
        >
          <h6 class="cart-item-title mb-0">
            <b-link class="text-body">
              {{ item.provider_service.main_service ? item.provider_service.main_service.service_name : '' }}
            </b-link>
          </h6>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <h5
            v-if="item.provider"
            class="cart-item-price text-left"
          >
            {{ item.provider.currency_symbol }} {{ item.service_price }}
          </h5>
          <div class="cart-item-qty">
            <b-form-spinbutton
              v-model="item.quantity"
              min="0"
              size="sm"
              @change="updateQuantity(item)"
            />
          </div>
        </div>
      </b-media>
    </li>

    <!-- Cart Footer -->
    <li
      v-if="cartItems && cartItems.length"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          {{ $t('Total') }}:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ storeProviderCurrency }} {{ totalAmount }}
        </h6>
      </div>
      <b-button
        v-if="!isShopOrProvider"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="checkout"
      >
        {{ $t('Checkout') }}
      </b-button>
    </li>

    <p
      v-if="cartItems && cartItems.length === 0"
      class="m-0 p-1 text-center"
    >
      {{ $t('Your cart is empty') }}
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton, BFormCheckboxGroup, BFormCheckbox, BModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { isUserLoggedIn } from '@/auth/utils'
import { useUserUi } from '@/views/users/useUser'
import FormSpinButton from '@/views/shared/FormSpinButton.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BModal,
    BImg,
    BFormSpinbutton,
    BButton,
    BFormCheckboxGroup,
    BFormCheckbox,
    FormSpinButton,
  },
  directives: {
    Ripple,
  },
  props: {
    isShopOrProvider: {
      type: Boolean,
      default: false,
    },
    isProvider: {
      type: Boolean,
      default: false,
    },
    orderPayload: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      addOnsData: null,
      showAddOns: false,
      addOnsPayload: [],
      selectedAddOns: [],
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.cartItems.forEach(i => {
        if (!this.isServicePage) {
          total += i.total_item_price
        } else {
          total += i.total_service_price
        }
      })
      return parseFloat(parseFloat(total).toFixed(2))
    },
    cartItemsCount() {
      return this.isShopOrProvider ? this.cartItems.length : this.$store.state['app-ecommerce'].cartItemsCount
    },
    cartItems() {
      // eslint-disable-next-line no-nested-ternary
      return this.isShopOrProvider ? (this.orderPayload.carts ? this.orderPayload.carts : []) : this.$store.state['app-ecommerce'].cartItems
    },
    entireCart() {
      return this.isShopOrProvider ? this.orderPayload : this.$store.state['app-ecommerce'].entireCart
    },
    isServicePage() {
      // if (this.isProvider) return true
      // if (this.$route.meta.isServicePage) return true
      if (this.cartItems && this.cartItems[0] && this.cartItems[0].provider) {
        return true
      }
      return false
    },
    storeProviderCurrency() {
      const { cartItems } = this
      if (cartItems && cartItems.length > 0) {
        if (cartItems[0].provider) {
          return cartItems[0].provider.currency_symbol
        }
        if (cartItems[0].store) {
          return cartItems[0].store.currency_symbol
        }
      }
      return ''
    },
  },
  mounted() {
    if (!this.isShopOrProvider) {
      this.fetchItems()
      this.fetchAddress()
    }
  },
  methods: {
    addItemToCart(payload) {
      this.$emit('value-changed', payload)
    },
    canShowPrice(price) {
      if (!price) return false
      // eslint-disable-next-line radix
      return parseInt(price) !== 0
    },
    addItemsOns() {
      this.updateQuantity(this.addOnsData, this.selectedAddOns.join(','))
    },
    editAddOns(props) {
      this.addOnsData = props

      const { getAddOnsInfo } = useUserUi()
      this.selectedAddOns = []
      showLoader()
      getAddOnsInfo(props.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.addOnsPayload = data.responseData
            this.selectedAddOns = Object.values(this.addOnsPayload.itemcartaddon)
            this.showAddOns = true
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    checkout() {
      if (this.isServicePage) {
        this.$router.push({
          name: 'apps-service-checkout',
        })
      } else if (this.entireCart.slug) {
        this.$router.push({
          path: `/user/checkout-shop/${this.entireCart.slug}`,
        })
      }
    },
    fetchAddress() {
      if (!isUserLoggedIn()) {
        return
      }
      if (window.addressCallInProgress) {
        return
      }
      if (this.$store.state['app-user'].userAddressList.length > 0) {
        return
      }
      if (!window.addressCallInProgress) {
        window.addressCallInProgress = true
      }
      this.$store.dispatch('app-user/getUserAddressInfo').then(response => {
        if (response.data.statusCode === '200') {
          this.$store.commit('app-user/UPDATE_USER_ADDRESS_LIST', response.data.responseData)
        } else {
          this.$store.commit('app-user/UPDATE_USER_ADDRESS_LIST', [])
        }
        window.addressCallInProgress = false
      })
    },
    async fetchItems() {
      if (this.$store.state['app-ecommerce'].cartItems.length === 0) {
        let moduleLink = 'app-ecommerce/fetchCartProducts'
        const { currentCart } = useUserUi()
        const data = await currentCart()
        let isServicePage = false
        if (data.data && data.data.responseData && data.data.responseData.cart === 'PROVIDER') {
          isServicePage = true
        }
        if (isServicePage) moduleLink = 'app-ecommerce/fetchServiceCartProducts'
        this.$store
          .dispatch(moduleLink)
          .then(response => {
            if (response.data.statusCode === '200') {
              this.items = response.data.responseData.carts
              const cart = response.data.responseData
              if (isServicePage && !cart.payments) {
                cart.payments = {
                  paypal: cart.paypal,
                  phonepe: cart.phonepe,
                  razor: cart.razor,
                  stripe: cart.stripe,
                  stripe_publishable_key: cart.stripe_key,
                }
              }
              this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', cart)
            } else {
              this.items = []
            }
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', this.items)
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      } else {
        this.items = this.$store.state['app-ecommerce'].cartItems
      }
    },
    removeItemFromCart(item) {
      showLoader()
      const formData = new FormData()
      formData.append('cart_id', item.id)
      this.items = this.items.filter(x => x.id !== item.id)
      this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
      this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', this.items)
      this.$store
        .dispatch(item.provider_service ? 'app-ecommerce/removeServiceFromCart' : 'app-ecommerce/removeFromCart', formData)
        .then(response => {
          if (response.data.statusCode === '200') {
            this.items = response.data.responseData.carts
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', this.items)
            this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', response.data.responseData)
            showSuccessNotification(this, 'Item removed successfully')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    updateQuantity(item, addOns = '') {
      if (this.isShopOrProvider) {
        this.$emit('value-changed', item)
        return
      }
      if (item.quantity === 0) {
        this.removeItemFromCart(item)
        return
      }
      showLoader()
      this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', this.items)
      const formData = new FormData()
      formData.append('item_id', item.provider_service ? item.provider_service.id : item.product.id)
      formData.append('cart_id', item.id)
      formData.append('qty', item.quantity)
      formData.append('addons', addOns || '')
      formData.append('repeat', 1)
      formData.append('customize', 0)
      let moduleLink = 'app-ecommerce/updateQuantity'
      if (this.isServicePage) moduleLink = 'app-ecommerce/updateServiceQuantity'
      this.$store
        .dispatch(moduleLink, formData)
        .then(response => {
          if (response.data.statusCode === '200') {
            this.items = response.data.responseData.carts
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', this.items)
            this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', response.data.responseData)
            showSuccessNotification(this, 'Quantity updated successfully')
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}

.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media img {
  width: 100%;
}
</style>

<style lang="scss">

.flex-wrapper-cart {
  .media-body {
    display: block !important;
  }
}

.store-cart-option-button {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0 !important;

  &.b-nav-dropdown .dropdown-toggle::after {
    background-image: unset !important;
  }

  .badge.badge-up {
    right: 0px;
  }

  .ml-mobile-0 {
    margin-left: 0 !important;
  }

  ul.dropdown-menu.dropdown-menu-media.dropdown-menu-right.show {
    width: 90vw;
    padding: 5px 10px;

    .media-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    .media {
      padding: 0.9rem 5px;
      border: none;
      border-bottom: 1px solid #ebe9f1;
    }

    img.rounded {
      width: 40px;
    }

    .cart-item-remove {
      position: absolute;
      top: -5px;
      width: 14px;
      height: 14px;
      right: 0;
      cursor: pointer;
    }

    .cart-item-price {
      margin-bottom: 0 !important;
      white-space: nowrap;
    }

    .cart-item-by {
      display: none;
    }
  }
}
</style>
