<template>
  <div
    v-if="activeOrderDetail"
    class="row order-detail-modal-wrapper"
  >
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <p>{{ $t('Order Id') }}: {{ activeOrderDetail.store_order_invoice_id }}</p>
        </div>
        <div
          v-if="activeOrderDetail.user"
          class="col-md-12"
        >
          <p>{{ $t('Customer Name') }}: {{ activeOrderDetail.user.first_name }}</p>
        </div>
        <div class="col-md-12">
          <p class="d-flex">
            {{ $t('Contactless Delivery') }}:
            {{ activeOrderDetail.contactless_delivery ? 'Yes' : 'No' }}
          </p>
        </div>
        <div class="col-md-12">
          <p class="d-flex">
            {{ $t('Payment Mode:') }}
            {{ activeOrderDetail.payment_mode }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row">
        <div
          v-if="activeOrderDetail.pickup"
          class="col-md-12"
        >
          <p>{{ $t('Shop Name') }}: {{ activeOrderDetail.pickup.store_name }}</p>
        </div>
        <div class="col-md-12">
          <p>{{ $t('Delivery Address') }}: {{ activeOrderDetail.delivery ? activeOrderDetail.delivery.map_address : '' }}</p>
        </div>
        <div
          v-if="activeOrderDetail.user"
          class="col-md-12"
        >
          <p>{{ $t('Phone Number') }}: {{ activeOrderDetail.user.mobile }}</p>
        </div>
        <div class="col-md-12">
          <p class="d-flex">
            {{ $t('Payment Status') }}:
            {{ activeOrderDetail.paid ? 'Paid' : 'Not Paid' }}
          </p>
        </div>
        <div
          v-if="activeOrderDetail.invoice"
          class="col-md-12"
        >
          <p>{{ $t('Total Amount') }}: {{ activeOrderDetail.invoice.total_amount }} {{ activeOrderDetail.currency }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row">
        <div
          v-if="activeOrderDetail.order_type == 'DELIVERY' && activeOrderDetail.without_item == 1"
          class="col-md-12"
        >
          <p>{{ $t('COD Amount') }}: {{ activeOrderDetail.currency }} {{ activeOrderDetail.cod }}</p>
        </div>
        <div class="col-md-12">
          <p>{{ $t(isBooking ? 'Booking Date' : isShopView ? 'Created Date' : 'Delivery Date') }}: {{ isShopView ? activeOrderDetail.created_time : activeOrderDetail.delivery_date }}</p>
        </div>
        <div
          v-if="isBooking"
          class="col-md-12"
        >
          <p>{{ $t('Created Date') }}: {{ activeOrderDetail.created_time }}</p>
        </div>
        <div class="col-md-12">
          <p class="d-flex">
            {{ $t('Note') }}:
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="activeOrderDetail.description" />
            <!--eslint-enable-->
          </p>
        </div>
        <div class="col-md-12">
          <p>{{ $t('Status') }}: {{ activeOrderDetail.status }}</p>
        </div>
        <div
          v-if="isBooking"
          class="col-md-12"
        >
          <p>{{ $t('No of persons') }}: {{ activeOrderDetail.number_guests }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <p class="mb-1">
        {{ $t('Courier Options') }}
      </p>
      <div class="d-flex align-items-between">
        <button
          class="btn btn-primary mb-1 mx-50 w-100"
          @click="shopRocketManifest"
        >
          {{ $t('Manifest') }}
        </button>
        <button
          class="btn btn-primary mb-1 mx-50 w-100"
          @click="shopRocketLabel"
        >
          {{ $t('Label') }}
        </button>
      </div>
      <div class="d-flex align-items-between">
        <button
          class="btn btn-primary mb-1 mx-50 w-100"
          @click="shopRocketInvoice"
        >
          {{ $t('Invoice') }}
        </button>
        <button
          class="btn btn-primary mb-1 mx-50 w-100"
          @click="shopRocketTracking"
        >
          {{ $t('Tracking') }}
        </button>
      </div>
    </div>
    <div
      v-if="activeOrderDetail.invoice"
      class="col-md-12 table-responsive"
    >
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th
              class="text-black pt-pb-5 px-1 w-100"
              colspan="6"
            >
              <div class="d-grid grid-columns-template-wrapper">
                <p class="mb-0">
                  {{ $t('Product Name') }}
                </p>
                <p class="mb-0">
                  {{ $t('Note') }}
                </p>
                <p class="mb-0">
                  {{ $t('Price') }}
                </p>
                <p class="mb-0">
                  {{ $t('Unit') }}
                </p>
                <p class="mb-0">
                  {{ $t('Quantity') }}
                </p>
                <p class="mb-0">
                  {{ $t('Cost') }}
                </p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) of activeOrderDetail.invoice.items"
            :key="index"
          >
            <td
              class="text-black pt-pb-5 px-1 w-100"
              colspan="6"
              :class="{ 'border-bottom': index != activeOrderDetail.invoice.items.length - 1 }"
            >
              <div class="d-grid grid-columns-template-wrapper">
                <p class="mb-0">
                  {{ item.product ? item.product.item_name : '' }}
                </p>
                <p class="mb-0">
                  {{ item.note }}
                </p>
                <p class="mb-0">
                  {{ item.item_price }} {{ activeOrderDetail.currency }}
                </p>
                <p class="mb-0">
                  {{ item.product && item.product.unit_value ? item.product.unit_value : '' }} {{ item.product && item.product.main_unit ? item.product.main_unit.name : '' }}
                </p>
                <p class="mb-0">
                  {{ item.quantity }}
                </p>
                <p class="mb-0">
                  {{ item.item_price * item.quantity }} {{ activeOrderDetail.currency }}
                </p>
              </div>
              <p
                v-if="item.cartaddon && item.cartaddon.length > 0"
                class="mb-0"
              >
                ({{ item.cartaddon.length }} {{ $t('addons') }})
              </p>
              <div
                v-if="item.cartaddon && item.cartaddon.length > 0"
                class="addons-wrapper"
              >
                <div
                  v-for="(addon, counter) of item.cartaddon"
                  :key="counter"
                  class="d-grid grid-columns-template-wrapper"
                >
                  <p class="mb-0">
                    {{ addon.addon_name }}
                  </p>
                  <p class="mb-0" />
                  <p class="mb-0" />
                  <p class="mb-0" />
                  <p class="mb-0">
                    {{ item.quantity }}
                  </p>
                  <p class="mb-0">
                    {{ addon.addon_price }}{{ activeOrderDetail.currency }}
                  </p>
                </div>
              </div>
              <p
                v-if="getVariations(item.product_data).length > 0"
                class="mb-0 mt-1 font-weight-bolder"
              >
                {{ $t('Other details') }}
              </p>
              <ul
                v-if="getVariations(item.product_data).length > 0"
                style="padding-left: 15px"
                class="mb-0"
              >
                <li
                  v-for="(addon, count) of getVariations(item.product_data)"
                  :key="count"
                >
                  {{ addon[0] }}: {{ addon[1] }}
                </li>
              </ul>
            </td>
          </tr>
          <tr v-if="activeOrderDetail.invoice">
            <td
              class="text-black pt-pb-5 px-1 w-100"
              colspan="6"
            >
              <div class="d-grid grid-columns-template-wrapper-extra">
                <p class="mb-0 text-right pr-2">
                  {{ $t('Discount') }}:
                </p>
                <p class="mb-0">
                  {{ activeOrderDetail.invoice.discount }} {{ activeOrderDetail.currency }}
                </p>
              </div>
            </td>
          </tr>
          <tr v-if="activeOrderDetail.invoice">
            <td
              class="text-black pt-pb-5 px-1 w-100"
              colspan="6"
            >
              <div class="d-grid grid-columns-template-wrapper-extra">
                <p class="mb-0 text-right pr-2">
                  {{ $t('Delivery Charge') }}:
                </p>
                <p
                  v-if="activeOrderDetail.invoice.delivery_amount"
                  class="mb-0"
                >
                  {{ activeOrderDetail.invoice.delivery_amount }} {{ activeOrderDetail.currency }}
                </p>
                <p
                  v-else
                  class="mb-0"
                >
                  FREE
                </p>
              </div>
            </td>
          </tr>
          <tr v-if="activeOrderDetail.invoice">
            <td
              class="text-black pt-pb-5 px-1 w-100"
              colspan="6"
            >
              <div class="d-grid grid-columns-template-wrapper-extra">
                <p class="mb-0 text-right pr-2">
                  {{ $t('Packing Charge') }}:
                </p>
                <p class="mb-0">
                  {{ activeOrderDetail.invoice.store_package_amount }} {{ activeOrderDetail.currency }}
                </p>
              </div>
            </td>
          </tr>
          <tr v-if="activeOrderDetail.invoice">
            <td
              class="text-black pt-pb-5 px-1 w-100"
              colspan="6"
            >
              <div class="d-grid grid-columns-template-wrapper-extra">
                <p class="mb-0 text-right pr-2">
                  {{ $t('GST') }}:
                </p>
                <p class="mb-0">
                  {{ activeOrderDetail.invoice.tax_amount }} {{ activeOrderDetail.currency }}
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="text-black pt-pb-5 px-1 w-100"
              colspan="6"
            >
              <div class="d-grid grid-columns-template-wrapper-extra">
                <p class="mb-0 text-right pr-2">
                  {{ $t('Total') }}:
                </p>
                <p class="mb-0">
                  {{ activeOrderDetail.invoice.total_amount }} {{ activeOrderDetail.currency }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="isShop && isBooking"
      class="col-md-12 text-center my-2"
    >
      <button
        class="btn btn-primary"
        @click="viewUpdateRequestModal = true"
      >
        {{ $t('Reschedule') }}
      </button>
    </div>
    <b-modal
      id="updateRequestModalOrderDetail"
      v-model="viewUpdateRequestModal"
      :no-close-on-backdrop="true"
      :title="$t('Update Request')"
      @hidden="viewUpdateRequestModal = false"
      @ok="reScheduleRequest"
    >
      <div class="wrap-update-request">
        <div class="col-md-12">
          <b-form-group :label="$t('Schedule time')">
            <custom-calender
              ref="calenderRef"
              :hide-submit="true"
              :timing="storeCurrentTimings"
              :all-timings="providerTimings"
              :store-slot-info="providerTimings"
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BFormGroup, BModal } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from '@/views/users/useUser'
import eventBus from '@/libs/event-bus'
import moment from 'moment'
import { useStoreUi } from '../useStore'
import CustomCalender from '../../users/shared/CustomCalender.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    CustomCalender,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    activeOrderDetail: {
      typeof: Object,
      default: () => {},
    },
    isBooking: {
      type: Boolean,
      default: false,
    },
    isShopView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewUpdateRequestModal: false,
      scheduleDate: null,
      providerTimings: [],
      storeCurrentTimings: null,
    }
  },
  computed: {
    isShop() {
      return window.SuperApp.getters.isStore()
    },
    storeTimings() {
      if (!this.providerTimings) return null
      const currentDay = moment().format('ddd').toUpperCase()
      return this.providerTimings.find(x => x.store_day === currentDay)
    },
  },
  mounted() {
    if (this.isShop && this.isBooking) {
      this.getStoreTimeslots()
    }
  },
  methods: {
    shopRocketManifest() {
      const { shopRocketManifest } = useStoreUi()

      showLoader()
      shopRocketManifest(`order_ids[0]=${this.activeOrderDetail.id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            //
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    shopRocketLabel() {
      const { shopRocketLabel } = useStoreUi()

      showLoader()
      shopRocketLabel(`order_ids[0]=${this.activeOrderDetail.id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData.label_created !== 1 && data.responseData.response) {
              showDangerNotification(this, data.responseData.response)
            } else {
              showSuccessNotification(this, data.responseData.response)
              window.open(data.responseData.label_url)
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    shopRocketInvoice() {
      const { shopRocketInvoice } = useStoreUi()
      showLoader()
      shopRocketInvoice(`order_ids[0]=${this.activeOrderDetail.id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData.is_invoice_created) {
              window.open(data.responseData.invoice_url)
            } else {
              showDangerNotification(this, 'Failed to generate Invoice')
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    shopRocketTracking() {
      const { shopRocketTracking } = useStoreUi()

      showLoader()
      shopRocketTracking(`order_id=${this.activeOrderDetail.id}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            if (data.responseData.tracking_data) {
              if (data.responseData.tracking_data.error) {
                showDangerNotification(this, data.responseData.tracking_data.error)
              }
            }
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getVariations(products) {
      if (!products) return []
      try {
        return Object.entries(JSON.parse(products))
      } catch (error) {
        return []
      }
    },
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getStoreTimeslots() {
      const { getStoreTimeslots } = useUserUi()
      getStoreTimeslots(`${window.SuperApp.getters.userInfo().slug}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData.timings
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    reScheduleRequest() {
      const { reScheduleRequest } = useStoreUi()
      const formData = new FormData()
      let date = ''
      let year = ''
      let time = ''
      let month = ''
      let day = ''
      if (this.$refs.calenderRef) {
        this.scheduleDate = new Date(`${this.$refs.calenderRef.$data.activeDate.toDateString()} ${this.$refs.calenderRef.$data.activeTimeSlot}`)
      }
      if (this.scheduleDate) {
        const splitDate = this.scheduleDate
        date = splitDate
        year = new Date(date).getFullYear()
        month = new Date(date).getMonth() + 1
        day = new Date(date).getDate()
        date = `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}`
        if (this.$refs.calenderRef) {
          time = this.$refs.calenderRef.$data.activeTimeSlot
        }
      }
      formData.append('id', this.activeOrderDetail.id)
      formData.append('date', date)
      formData.append('time', time)
      formData.append('year', year)
      formData.append('day', day)
      formData.append('month', month)
      showLoader()
      reScheduleRequest(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Booking updated successfully')
            if (window.SuperApp.getters.isStore()) {
              eventBus.$emit('user-sync-app', {
                userId: this.activeOrderDetail.user_id,
                rescheduled: true,
                shopName: window.SuperApp.getters.userInfo().store_name,
                invoiceId: this.activeOrderDetail.store_order_invoice_id,
              })
            }
            this.viewUpdateRequestModal = false
          } else {
            showDangerNotification(this, data.message)
            this.viewUpdateRequestModal = true
          }
          hideLoader()
        })
        .catch(error => {
          this.viewUpdateRequestModal = true
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.order-detail-modal-wrapper .grid-columns-template-wrapper {
  grid-template-columns: 25% 15% 15% 15% 15% 15%;
  display: grid;
}

.order-detail-modal-wrapper .grid-columns-template-wrapper-extra {
  grid-template-columns: 85% 15%;
  display: grid;
}
</style>

<style>
#updateRequestModalOrderDetail .modal-body {
  max-height: 75vh;
  overflow-y: auto;
}
</style>
