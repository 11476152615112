<template>
  <div class="provider-agent-requests">
    <b-card>
      <b-row>
        <b-col cols="12">
          <good-data-table
            ref="myRequestsGrid"
            :columns="myRequestColumn"
            :total-column="isProviderAgent ? 'response.responseData.service.total' : 'response.responseData.total'"
            :data-column="isProviderAgent ? 'response.responseData.service.data' : 'response.responseData.data'"
            :api-endpoint="apiEndpoint"
            :no-serial="true"
            :export-map="isProviderAgent ? 'res.responseData.service' : 'res.responseData'"
            @table-response="accpetedResponse"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="viewOrderInfoModal"
      v-model="showOrderInfo"
      :no-close-on-backdrop="true"
      :title="$t('Order Details')"
      size="lg"
      @hidden="showOrderInfo = false"
    >
      <order-detail :active-order-detail="activeOrderDetail" />
    </b-modal>
    <b-modal
      v-model="viewOrderDetailModal"
      :no-close-on-backdrop="true"
      :title="$t('Order Details')"
      hide-footer
      @hidden="viewOrderDetailModal = false"
    >
      <order-info :order-detail="orderDetail" />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { BCard, BRow, BCol, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import OrderDetail from '../store/shared/OrderDetail.vue'
import { useProviderUi } from './useProvider'
import OrderInfo from './agent/OrderInfo.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    GoodDataTable,
    BModal,
    OrderDetail,
    OrderInfo,
  },
  data() {
    return {
      orderAction: null,
      showOrderInfo: false,
      activeOrderDetail: null,
      orderDetail: {},
      viewOrderDetailModal: false,
      statusImages: {
        DELIVERY: require('@/assets/images/app-icons/express-delivery.png'),
        WALLET: require('@/assets/images/app-icons/wallet.png'),
        'DINE IN': require('@/assets/images/app-icons/shop.png'),
        POS: require('@/assets/images/app-icons/POS.jpeg'),
        TAKEAWAY: require('@/assets/images/app-icons/take-away.png'),
        CASH: require('@/assets/images/app-icons/cash_new.png'),
        CARD: require('@/assets/images/app-icons/CARD.png'),
        'CARD ON DELIVERY': require('@/assets/images/app-icons/CARD ON DELIVERY.png'),
        CARD_ON_DELIVERY: require('@/assets/images/app-icons/CARD_ON_DELIVERY.png'),
        on_delivery: require('@/assets/images/app-icons/CARD_ON_DELIVERY.png'),
      },
      providerAllRequest: [],
      serviceHistoryProps: {
        tripHistoryObject: null,
        currentServiceForDispute: {},
        upcomingServiceDetailVisible: false,
        viewDisputeModal: false,
        currentDispute: '',
        disputeObject: {},
        viewHistoryModal: false,
        requestHistoryList: [],
        viewUpdateRequestModal: false,
        updateRequest: {
          selectedStatus: '',
          notes: '',
          scheduleDate: '',
          assignedAgent: '',
          beforePickup: null,
        },
      },
      requestStatusList: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Accepted',
          value: 'ACCEPTED',
        },
        {
          label: 'Scheduled',
          value: 'SCHEDULED',
        },
        {
          label: 'Started',
          value: 'STARTED',
        },
        {
          label: 'Assigned',
          value: 'ASSIGNED',
        },
        {
          label: 'Arrived',
          value: 'ARRIVED',
        },
        {
          label: 'Pickup',
          value: 'PICKEDUP',
        },
        {
          label: 'Dropped',
          value: 'DROPPED',
        },
        {
          label: 'Completed',
          value: 'COMPLETED',
        },
        {
          label: 'Cancelled',
          value: 'CANCELLED',
        },
      ],
      disputeOptions: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Customer denied to pay amount',
          value: 'Customer denied to pay amount',
        },
        {
          label: 'User provided wrong service information',
          value: 'User provided wrong service information',
        },
        {
          label: 'User neglected to pay additional charge',
          value: 'User neglected to pay additional charge',
        },
        {
          label: 'User provided less amount',
          value: 'User provided less amount',
        },
        {
          label: 'Others',
          value: 'Others',
        },
      ],
      agentListProvider: [],
    }
  },
  computed: {
    that() {
      return this
    },
    myRequestColumn() {
      return [
        this.isProviderAgent
          ? {
              label: 'Service Id',
              field: 'booking_id',
            }
          : {
              label: 'Booking Id',
              field: 'store_order_invoice_id',
            },
        {
          label: 'Date',
          field: 'assigned_time',
        },
        {
          label: 'Customer',
          field: 'first_name',
          resolvePath: 'user.first_name',
          useRenderer: true,
          useResolver: true,
          useHtml: true,
          excelHtml: props => {
            if (props.user) {
              return `${props.user.first_name} ${props.user.last_name}`
            }
            return ''
          },
          renderer: props => {
            if (props.user) {
              return `<div class="d-flex align-items-start justify-content-center">
                        <img src="${window.SuperApp.helper.image(props.user.picture)}" onerror="this.src = '${
                window.SuperApp.constants.noImageAvailable
              }'" style="width: 40px; height: 40px; border-radius: 10%;" />
                        <div class="wrap-info">
                            <p class="mb-0 ml-1">${props.user.first_name} ${props.user.last_name}</p>
                        </div>
                      </div>`
            }
            return ''
          },
        },
        {
          label: 'Amount',
          field: this.isProviderAgent ? 'payment.total' : 'order_invoice.total_amount',
          useRenderer: true,
          useResolver: true,
          renderer: props => {
            if (props.order_invoice) {
              return `${props.order_invoice.total_amount} ${props.user && props.user.currency_symbol ? props.user.currency_symbol : ''}`
            }
            if (props.payment) {
              return `${props.payment.total} ${props.user && props.user.currency_symbol ? props.user.currency_symbol : ''}`
            }
            return ''
          },
        },
        // this.isProviderAgent ? null : {
        //   label: 'Order',
        //   field: 'order_invoice.items',
        //   resolvePath: 'order_invoice_path',
        //   useRenderer: true,
        //   useResolver: true,
        //   useHtml: true,
        //   excelHtml: props => {
        //     if (props.order_invoice) {
        //       return props.order_invoice.items.map(x => `Product: ${x.product.item_name}. Quantity: ${x.item_quantity}`).join(',')
        //     }
        //     return ''
        //   },
        //   renderer: props => {
        //     if (props.order_invoice) {
        //       return `<div class="d-block">
        //                 ${props.order_invoice.items.map(x => `<p class="mb-0 ml-1">${x.item_quantity} ${x.product.item_name}</p>`).join('')}
        //               </div>`
        //     }
        //     return ''
        //   },
        // },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Payment',
          field: 'payment_mode',
          useResolver: true,
          useRenderer: true,
          useHtml: true,
          excelHtml: props => {
            if (props.user && props.order_invoice) {
              return props.order_invoice.payment_mode
            }
            if (props.user && props.payment) {
              return props.payment.payment_mode
            }
            return ''
          },
          renderer: props => {
            if (props.user && props.order_invoice) {
              return `<img style="width: 30px; height: 30px;" src="${this.statusImages[props.order_invoice.payment_mode]}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />`
            }
            if (props.user && props.payment) {
              return `<img style="width: 30px; height: 30px;" src="${this.statusImages[props.payment.payment_mode]}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />`
            }
            return ''
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          buttons: [
            {
              icon: 'track',
              title: 'Order Information',
              image: require('@/assets/images/app-icons/seo.png'),
              action: props => {
                this.orderDetail = props
                this.viewOrderDetailModal = true
              },
            },
          ],
        },
      ].filter(x => x)
    },
    disputeObject() {
      return this.serviceHistoryProps.disputeObject && this.serviceHistoryProps.disputeObject.id
    },
    apiEndpoint() {
      return this.isProviderAgent ? '/provider/agent/history/service' : '/provider/order/agent/history/order'
    },
    agentInfo() {
      return window.SuperApp.getters.userInfo()
    },
    isProviderAgent() {
      return this.agentInfo.parent_type === 'PROVIDER'
    },
    isAgent() {
      return window.SuperApp.getters.isAgent()
    },
  },
  mounted() {},
  methods: {
    updateRequestApproval(props) {
      if (props.status === 'ACCEPTED') {
        this.$swal({
          title: this.$t('You want to accept this request?'),
          iconHtml: this.$helpers.swalIcon('confirm.png'),
          showCancelButton: true,
          confirmButtonText: this.$t('Yes'),
          cancelButtonText: this.$t('No'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            icon: 'border-0',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.acceptOrderRequest()
          }
        })
      }
      this.orderAction = props.payload
    },
    viewServiceHistory(payload) {
      this.showOrderInfo = true
      this.activeOrderDetail = payload
    },
    accpetedResponse(payload) {
      this.providerAllRequest = payload.responseData.data
    },
    getCategoryName(props) {
      if (props.service) {
        return `${props.service.service_category.service_category_name} => ${props.service.servicesub_category.service_subcategory_name}`
      }
      return 'N/A'
    },
    getServicePrice(props) {
      if (props.price && props.user) {
        return `${props.price} ${props.user.currency_symbol}`
      }
      return 'N/A'
    },
    acceptOrderRequest() {
      showLoader()
      const { acceptOrderRequest } = useProviderUi()
      const formData = new FormData()
      formData.append('id', this.orderAction.id)
      formData.append('admin_service', 'ORDER')
      acceptOrderRequest(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            // eslint-disable-next-line eqeqeq
            if (data.responseData.status == 422) {
              showDangerNotification(this, data.message ? data.message : data.responseData.message)
            } else {
              showSuccessNotification(this, data.message ? data.message : data.responseData.message)
            }
            this.$refs.myRequestsGrid.loadItems()
          } else if (data.message) {
            showDangerNotification(this, data.message)
          } else if (data.responseData.message) {
            showDangerNotification(this, data.responseData.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#requestHistoryModal .modal-body {
  max-height: 80vh;
  overflow-y: auto;
}

#updateRequestModal .modal-body {
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
}
</style>

<style lang="scss" scoped>
.wrap-request-items {
  width: 33.33%;
  display: inline-block;
  margin-right: 20px;
}

.grid-view {
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
  padding: 10px;
}

.request-date-price {
  background: #f8f8f8;
  padding: 8px 5px;
}
</style>
